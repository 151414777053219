import axios, {AxiosResponse} from 'axios'
import {sendLeadDetailsToSlack} from 'v1/utils/slack'

export const API_END_POINT = process.env.NEXT_PUBLIC_API_END_POINT

export const apiAxios = axios.create({
  baseURL: API_END_POINT as string | undefined,
  timeout: 120000,
  adapter: 'xhr',
  headers: {
    'Content-Type': 'application/json',
  },
})

interface RegisterPayload {
  [key: string]: any
}

export class AuthService {
  static async register(payload: RegisterPayload): Promise<AxiosResponse> {
    return apiAxios.post(`/users/admin`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
}

export const handleRegister = async (
  payload: RegisterPayload,
  setDemoBooked: (status: boolean) => void,
  setApiErrorMessage: (message: string) => void,
): Promise<void> => {
  try {
    const {data} = await AuthService.register(payload)

    if (data.message === 'Success') {
      setDemoBooked(true)
    }
  } catch (error: any) {
    if (error?.status == 409) {
      if (!error?.response?.data?.success) {
        setApiErrorMessage(error?.response?.data?.message)
      }
    }
  }
}

interface FormData {
  [key: string]: any
}

export interface IErrors {
  workEmail?: string
  [key: string]: any
}

export const handleValidateEmail = async (
  domain: string,
  formData: FormData,
  isFreeTrialPage: boolean | undefined,
  setErrors: (errors: (prevErrors: IErrors) => IErrors) => void,
  setDemoBooked: (status: boolean) => void,
  setApiErrorMessage: (message: string) => void,
  isNewLeadsSW?: boolean,
): Promise<void> => {
  try {
    const response = await axios.post(
      `${API_END_POINT}/auth/validate-email`,
      JSON.stringify({
        domain: domain,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    if (response?.status === 200) {
      if (response?.data?.data?.blockDomain) {
        setErrors((prevErrors: IErrors) => ({
          ...prevErrors,
          workEmail: 'Please provide a valid work email.',
        }))
      } else {
        if (isFreeTrialPage) {
          const payload = {
            full_name: formData.fullName,
            work_email: formData.workEmail,
            company_name: formData.companyName,
            no_of_emp: formData.employees,
            notes: formData.notes,
            onboarding_type: 'trial_onboarding',
            country_code: formData.countryCode?.split('+')?.[1],
            phone_number: formData.phoneNumber,
            source_url: window?.location?.pathname,
          }
          if (!payload.notes?.length) delete payload.notes

          handleRegister({...payload}, setDemoBooked, setApiErrorMessage)
        } else {
          await sendLeadDetailsToSlack(
            {...formData, urlPath: window?.location?.pathname, isNewLeadsSW},
            () => {
              setDemoBooked(true)
            },
          )
        }
      }
    }
  } catch (error: any) {
    console.error('Error validating email:', error)
  }
}
