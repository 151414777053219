'use client'

import {useEffect, useState, ChangeEvent, type JSX} from 'react'
import Link from 'next/link'
import Image from 'next/image'
import Select, {SingleValue} from 'react-select'
import clsx from 'clsx'
import Button from 'v1/common-components/button/Button'
import PickASlotCard from './PickASlotCard'

import {getCountries} from 'v1/utils/_requests'
import {handleValidateEmail, IErrors} from 'v1/utils/services'
import {TERMS_OF_SERVICE, PRIVACY_POLICY} from 'v1/utils/getters'

import classes from './styles.module.css'
import userIcon from 'v1/assets/icons/ga/user.svg'
import mailIcon from 'v1/assets/icons/ga/mail.svg'
import phoneIcon from 'v1/assets/icons/ga/phone.svg'
import buildingIcon from 'v1/assets/icons/ga/building-2.svg'
import usersStageIcon from 'v1/assets/icons/ga/users-stage.svg'
import ArrowRightIcon from 'v1/assets/icons/arrow-right.svg'
import {getPublicErrorMessage} from 'utils/recaptcha'
import {useReCaptchaVerification} from 'hooks/useReCaptchaVerification'

export interface BookDemoProps {
  heading?: string
  buttonText?: string
  isNameRequired?: boolean
  isFreeTrialPage?: boolean
  ctaSupportingText?: string
  shouldValidateReCaptcha?: boolean
  isTermAndCondition?: boolean
  thankYouTitle?: string
  thankYouParagraph?: string
  thankYouCtaText?: string
  dontShowNoOfEmp?: boolean
  showNotes?: boolean
  isNewLeadsSW?: boolean
}

// ? Current Book Demo Form
const BookDemoForm = ({data}: {data?: BookDemoProps}) => {
  const {verifyUser, isVerifying, error: recaptchaError} = useReCaptchaVerification()
  const [formData, setFormData] = useState({
    fullName: '',
    workEmail: '',
    phoneNumber: '',
    countryCode: '',
    countryName: '',
    companyName: '',
    employees: '',
    notes: '',
  })
  const [countryCodes, setCountryCodes] = useState([])
  const [isDemoBooked, setDemoBooked] = useState(false)
  const [selectedCountry, setSelectedCountry] =
    useState<SingleValue<{label: JSX.Element; value: string; dialCode: string}>>(null)

  const [errors, setErrors] = useState<IErrors>({
    fullName: '',
    workEmail: '',
    phoneNumber: '',
    companyName: '',
    countryCode: '',
  })

  const [apiErrorMessage, setApiErrorMessage] = useState<string>()

  useEffect(() => {
    getCountries().then(res => setCountryCodes(res))
  }, [])

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const validatePhoneNumber = (number: string) => {
    const phoneRegex = /^\d{7,14}$/
    return phoneRegex.test(number)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e.target
    setFormData(prevData => ({...prevData, [name]: value}))
    setApiErrorMessage('')

    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: '',
    }))
  }

  const handleNoteChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value
    setFormData(prevData => ({...prevData, notes: text}))
  }

  function getEmailDomain(email: string): string {
    const domain = email.split('@')[1]
    return domain
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    let isValid = true
    const newErrors = {
      fullName: '',
      workEmail: '',
      phoneNumber: '',
      companyName: '',
      countryCode: '',
    }

    if (data?.isNameRequired && !formData.fullName) {
      newErrors.fullName = 'Name is required.'
      isValid = false
    }

    if (!formData.workEmail) {
      newErrors.workEmail = 'Work email is required.'
      isValid = false
    } else if (!validateEmail(formData.workEmail)) {
      newErrors.workEmail = 'Please enter a valid email address.'
      isValid = false
    }

    if (!formData.phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required.'
      isValid = false
    } else if (!validatePhoneNumber(formData.phoneNumber)) {
      newErrors.phoneNumber = 'Please enter a valid phone number.'
      isValid = false
    }

    if (!formData.companyName) {
      newErrors.companyName = 'Company name is required.'
      isValid = false
    }

    if (formData.phoneNumber && !selectedCountry) {
      newErrors.countryCode = 'Please select a country code.'
      isValid = false
    }

    setErrors(newErrors)

    if (isValid) {
      try {
        if (data?.shouldValidateReCaptcha) {
          const result = await verifyUser('book_demo')

          if (!result.success) {
            setApiErrorMessage('Security verification failed. Please try again.')
            return
          }

          if (result.score && result.score < 0.5) {
            setApiErrorMessage('Additional verification required. Please contact support.')
            return
          }
        }

        await handleValidateEmail(
          getEmailDomain(formData.workEmail),
          formData,
          data?.isFreeTrialPage,
          setErrors,
          setDemoBooked,
          setApiErrorMessage,
          data?.isNewLeadsSW,
        )
      } catch (error) {
        setApiErrorMessage(getPublicErrorMessage(error))
      }
    }
  }

  return isDemoBooked ? (
    <PickASlotCard
      isFreeTrialPage={data?.isFreeTrialPage}
      title={data?.thankYouTitle}
      paragraph={data?.thankYouParagraph}
      ctaText={data?.thankYouCtaText}
    />
  ) : (
    <form className={classes.form}>
      <h4 className="h4-v1">{data?.heading || 'Book a demo'}</h4>
      <div className={classes.innerForm}>
        <div className={classes.formGroup}>
          <div className={classes.inputContainer}>
            <Image loading="lazy" src={userIcon} alt="user-icon" />
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder={'Your full name' + (data?.isNameRequired ? '*' : '')}
              className="paragraph2-v1"
            />
          </div>
          {errors.fullName && <p className={classes.error}>{errors.fullName}</p>}
        </div>

        <div className={classes.formGroup}>
          <div className={classes.inputContainer}>
            <Image loading="lazy" src={mailIcon} alt="mail-icon" />
            <input
              id="workEmail"
              name="workEmail"
              value={formData.workEmail}
              onChange={handleChange}
              placeholder="Your work email*"
              className="paragraph2-v1"
            />
          </div>
          {errors.workEmail && <p className={classes.error}>{errors.workEmail}</p>}
        </div>

        <div className={classes.formGroup}>
          <div className={clsx(classes.contactInputContainer)}>
            <Select
              placeholder="Select country code*"
              value={selectedCountry}
              onChange={selectedOption => {
                if (selectedOption) {
                  setSelectedCountry(selectedOption)
                  setFormData(prevData => ({
                    ...prevData,
                    countryCode: selectedOption?.dialCode || '',
                    countryName: selectedOption?.value || '',
                  }))
                }
                setErrors(prevErrors => ({
                  ...prevErrors,
                  ['countryCode']: '',
                }))
              }}
              options={countryCodes?.map(
                (country: {flag: string; dial_code: string; name: string}) => ({
                  value: country.name,
                  dialCode: country.dial_code,
                  label: (
                    <div className={classes.countryCodeOption}>
                      <span>{country.flag}</span> <span>{country.dial_code}</span>
                      <span className={classes.countryName}>({country.name})</span>
                    </div>
                  ),
                }),
              )}
              className={clsx('paragraph2-v1', classes.selectCountry)}
            />
            <span className={classes.separatorLine}></span>
            <div className={classes.phoneNumberContainer}>
              <Image loading="lazy" src={phoneIcon} alt="phone-icon" />

              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder="Contact number*"
                className={clsx('paragraph2-v1', classes.w100)}
              />
            </div>
          </div>
          {errors.phoneNumber && <p className={classes.error}>{errors.phoneNumber}</p>}
          {errors.countryCode && <p className={classes.error}>{errors.countryCode}</p>}
        </div>

        <div className={classes.formGroup}>
          <div className={classes.inputContainer}>
            <Image loading="lazy" src={buildingIcon} alt="building-icon" />
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              placeholder="Company name*"
              className="paragraph2-v1"
            />
          </div>
          {errors.companyName && <p className={classes.error}>{errors.companyName}</p>}
        </div>

        {!data?.dontShowNoOfEmp ? (
          <div className={classes.formGroup}>
            <div className={classes.inputContainer}>
              <Image loading="lazy" src={usersStageIcon} alt="users-icon" />
              <Select
                id="employees"
                name="employees"
                value={numberOfEmployeesList.find(option => option.value === formData.employees)}
                onChange={selectedOption => {
                  setFormData(prevData => ({
                    ...prevData,
                    employees: selectedOption ? selectedOption.value : '',
                  }))
                }}
                placeholder="Number of employees"
                className={clsx('paragraph2-v1', classes.selectEmployee)}
                options={numberOfEmployeesList}
              />
            </div>
          </div>
        ) : null}

        {data?.showNotes ? (
          <div className={classes.formGroup}>
            <div className={classes.inputContainer} style={{height: '120px'}}>
              <textarea
                id="notes"
                name="notes"
                value={formData.notes}
                onChange={handleNoteChange}
                placeholder="Notes"
                className={clsx('paragraph2-v1', classes.notes)}
              />
            </div>
          </div>
        ) : null}

        {data?.isTermAndCondition ? (
          <div className={classes.termsContainer}>
            By clicking the button below, you agree to our{' '}
            <Link rel="preload" href={TERMS_OF_SERVICE} className={classes.linkText}>
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link rel="preload" href={PRIVACY_POLICY} className={classes.linkText}>
              Privacy Policy
            </Link>
            .
          </div>
        ) : null}

        {apiErrorMessage && <p className={classes.error}>{apiErrorMessage}</p>}
      </div>
      <Button
        text={data?.buttonText || 'Book a demo'}
        className={classes.submitButton}
        iconRight={
          <span className={classes.btnIconBox}>
            <Image loading="lazy" src={ArrowRightIcon} alt="login-icon" width={24} height={24} />
          </span>
        }
        isAnimate={true}
        onClick={handleSubmit}
      />
      {data?.ctaSupportingText && (
        <div className={classes.ctaSupportingText}>{data?.ctaSupportingText}</div>
      )}
    </form>
  )
}

const numberOfEmployeesList = [
  {
    value: '1-10',
    label: '1-10',
  },
  {value: '11-50', label: '11-50'},
  {value: '51-200', label: '51-200'},
  {value: '201-500', label: '201-500'},
  {value: '501+', label: '501+'},
]

export default BookDemoForm
